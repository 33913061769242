<header class="nav nav--fixed nav--mobile" role="navigation" aria-label="main navigation"
	[ngClass]="{'fixed': navIsFixed, 'active': isOpen, 'inactive': !isOpen }">
	<div class="container is-fluid">
		<div class="nav-grid">
			<div class="nav-grid__column nav__item">
				<a class="nav__logo" [routerLink]="['/']" routerLinkActive="router-link-active" cursor-active>
					<span class="is-sr-only">Zambezi</span>
					<svg class="nav__logo" id="navLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.41 87.56" fill="#fff">
						<path d="M72.97 29.2v58.36h14.59V14.59L72.97 29.2z"/>
						<path d="M124.05 0H72.97v14.59h51.08v18.26h-21.89l-14.6 14.59h36.49v40.12h14.59V14.61L124.05 0z"/>
						<path d="M350.26 32.85h-21.9l-14.59 14.59h36.49V32.85z"/>
						<path d="M357.55 14.59V0h-58.37v14.59h14.59L299.18 29.2v58.36h58.37V72.97h-43.78V14.59h43.78z"/>
						<path d="m280.93 40.15 10.95-10.95V14.61L277.29 0h-51.08v14.59h14.59L226.21 29.2v58.36h51.08l14.59-14.59V51.09l-10.95-10.95Zm-40.14 32.82V14.59h36.49v18.24h-21.89l-14.6 14.61h36.49v25.53h-36.49Z"/>
						<path d="M437.82 87.56h14.59V0h-14.59v14.59h14.59L437.82 29.2v58.36z"/>
						<path d="m372.15 72.97 58.37-43.78V0h-65.67v14.59h58.38l-58.38 43.79v29.18h65.67V72.97h-58.37z"/>
						<path d="m7.3 72.97 58.37-43.78V0H0v14.59h58.38L0 58.38v29.18h65.67V72.97H7.3z"/>
						<path d="M204.32 87.56h14.59V14.59L204.32 29.2v58.36z"/>
						<path d="m195.39 0-12.97 29.19L169.46 0h-23.52v14.59h14.59l14.6 32.85h14.59l14.6-32.85h14.59V0h-23.52z"/>
						<path d="M160.53 87.56V29.2l-14.59-14.61v72.97h14.59z"/>
					</svg>

				</a>
			</div>
			<div class="nav-grid__column nav__item item--button">
				<a role="button" class="nav__button" aria-label="menu" aria-expanded="false"
					cursor-active [ngClass]="{'open': isOpen}">
					<svg class="nav__burger hamburger" id="hamburger" viewBox="0 0 83 83">
						<line class="line01" x1="0" y1="3" x2="80" y2="3" />
						<line cursor-active class="line02" x1="0" y1="35" x2="80" y2="35" />
						<line class="line03" x1="0" y1="65" x2="80" y2="65" />
					</svg>
				</a>
			</div>
		</div>
	</div>
</header>
<div class="menu menu--mobile">
	<nav class="menu__navigation navigation navigation--mobile" role="navigation">
		<ul>
			<li>
				<a class="menu__item" routerLink="/work" routerLinkActive="active">
					<span>Work</span>
				</a>
			</li>
			<li>
				<a class="menu__item" routerLink="/capabilities" routerLinkActive="active">
					<span>Capabilities</span>
				</a>
			</li>
			<li>
				<a class="menu__item" routerLink="/about" routerLinkActive="active">
					<span>About</span>
				</a>
			</li>
			<li>
				<a class="menu__item" routerLink="/news" routerLinkActive="active">
					<span>News</span>
				</a>
			</li>
			<li>
				<a class="menu__item" routerLink="/careers" routerLinkActive="active">
					<span>Careers</span>
				</a>
			</li>
			<li>
				<a class="menu__item" routerLink="/contact" routerLinkActive="active">
					<span>Contact</span>
				</a>
			</li>
		</ul>
	</nav>
</div>
