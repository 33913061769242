import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Event } from '@angular/router';
import * as WebFont from 'webfontloader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import gsap from 'gsap';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppComponent implements OnInit {
  smoother;
  isNewSession: boolean;
  public isIntroDone = false;
  sawIntroAnimation = false;
  isDone = false;
  location: Location;
  animationType = '';
  modules = ['about', 'contact', 'home', 'work'];
  state: string;
  pageIndex = 0;
  animating: boolean;
  title = 'zmbz';
  currentAnimation?: gsap.core.Timeline;
  private classes: Array<string> = [];
  isCategoryPage = false;
  public categories = [
    'work',
    'people',
    'perspectives'
  ];

  removeBodyClasses() {
    this.classes.map((key: string) => {
      if (key.length > 0) {
        document.body.classList.remove(key + '-page');
      }
    });
  }

  addBodyClasses(path: any) {
    this.classes = path.replace(/\/[0-9]+/g, '').split('?')[0].split('/') && path.replace(/\/[0-9]+/g, '').split('?')[0].split('#');
    this.classes.map((key: string) => {

      if (key.length > 0) {
        document.body.classList.add(key + '-page');
      }

      if (key.length === 0) {
        document.body.classList.add('home-page');
      } else {
        document.body.classList.remove('home-page');
      }

      if (this.router.url.replace(/\/[0-9]+/g, '').split('?')[0].split('/')[3] === 'campaign') {
        document.body.classList.add('work-item-page');
      } else {
        document.body.classList.remove('work-item-page');
      }

      if (this.router.url.replace(/\/[0-9]+/g, '').split('?')[0].split('/')[1] === 'work') {
        document.body.classList.add('work-page');
        document.body.classList.add('projects-page');
      } else {
        document.body.classList.remove('work-page');
        document.body.classList.remove('projects-page');
      }

    });
  }

  constructor(private router: Router,
    private deviceService: DeviceDetectorService,
    location: Location) {
    this.location = location;
    const isCategoryPage =
      location.isCurrentPathEqualTo('/news/' + this.categories[0]) ||
      location.isCurrentPathEqualTo('/news/' + this.categories[1]) ||
      location.isCurrentPathEqualTo('/news/' + this.categories[2]);

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.removeBodyClasses();
        this.addBodyClasses(event.url.substring(1));
        gsap.set('.app-wrapper', { autoAlpha: 0 });
      }
      if (event instanceof NavigationStart) {
        gsap.set('.page-wipe-white svg', { scale: 1, opacity: 0 });
        this.leaveAnimation();
        gsap.set('.app-wrapper', { autoAlpha: 0 });
      }
      if (event instanceof NavigationEnd) {
        // setTimeout(() => this.enterAnimation(), 500);
      }

    });
  }

  get device(): any {
    return this.deviceService.getDeviceInfo();
  }
  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }
  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }
  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  ngAfterViewInit() {
    gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
    if (this.isDesktop) {
      this.smoother = ScrollSmoother.create({
        wrapper: 'app-root',
        content: '#smooth-content',
        smooth: 1,
        // effects: true,
        // normalizeScroll: true
      });
      ScrollTrigger.addEventListener('refresh', () => {
        //
        // when the page refreshes
        //
      });
      setTimeout(() => {
        // this.smoother.effects('[data-speed], [data-lag]');
        ScrollTrigger.refresh();
      }, 50);
    }
  }

  ngOnInit() {
    gsap.set('.app-wrapper', { autoAlpha: 0 });
    WebFont.load({
      google: {
        families: ['IBM Plex Sans', 'IBM Plex Serif']
      }
    });
    setTimeout(() => {
      this.checkCookie();
    }, 100);

  }

  setInitialState() {
    gsap.to(['.page-wipe-white', '.page-wipe-blue'], {
      duration: 1.5,
      height: '0',
      ease: 'power4.inOut',
      stagger: .08
    });
  }

  onRouteUpdate() {
    if (this.currentAnimation?.reversed()) {
      this.currentAnimation.play();
      return;
    }
    this.currentAnimation = this.scaleImgAnimation();
    this.done();
  }

  scaleImgAnimation() {
    const imgTimeline = gsap.timeline();

    imgTimeline.set('.page-wipe-white svg', { scale: 1, opacity: 0 })
      .to('.page-wipe-white svg', { duration: 0.2, opacity: 1, ease: 'none' })
      .to('.page-wipe-white svg', { duration: 3, scale: 1.5, ease: 'none' }, '<')
      .add(() => this.done(), '>+=1');

    return imgTimeline;
  }

  leaveAnimation() {
    const timeline = gsap.timeline({ defaults: { stagger: 0.03, duration: 0.9, ease: 'expo.inOut' } }),
      loaderPanels = gsap.utils.toArray('.loader__panel');
    gsap.set('.app-wrapper', { opacity: 0 });
    timeline.fromTo(loaderPanels, {
      clipPath: 'inset(0% 100% 0% 0%)',
      webkitClipPath: 'inset(0% 100% 0% 0%)'
    }, {
      duration: 1.4,
      clipPath: 'inset(0% 0% 0% 0%)',
      webkitClipPath: 'inset(0% 0% 0% 0%)'
    })
      .fromTo(loaderPanels, {
        clipPath: 'inset(0% 0% 0% 0%)',
        webkitClipPath: 'inset(0% 0% 0% 0%)'
      }, {
        duration: 1.4,
        clipPath: 'inset(100% 0% 0% 0%)',
        webkitClipPath: 'inset(100% 0% 0% 0%)',
        stagger: { from: 'end', each: 0.03, ease: 'sine.inOut' }
      }, '>')
      .to('.app-wrapper', { duration: 0.5, autoAlpha: 1, ease: 'power2.inOut' }, '>-=1.2');

    return timeline;
  }

  done() {
    this.isDone = true;
    this.sawIntroAnimation = false;
    document.body.classList.remove('intro-animation');
    sessionStorage.setItem('introAnimation', 'true');
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie() {
    const cookied = sessionStorage.getItem('introAnimation');
    if (cookied) {
      this.sawIntroAnimation = false;
      this.isIntroDone = true;
    } else {
      this.sawIntroAnimation = true;
      this.isIntroDone = false;
      this.scaleImgAnimation();
    }
  }


}
