import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
    Router, ActivatedRoute, RouterOutlet, RouterLink, NavigationEnd, RouterLinkActive,
    NavigationStart, NavigationCancel, NavigationError, Event
} from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    imports: [RouterOutlet, RouterLink, RouterLinkActive],
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    standalone: true
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
    location: Location;
    is404 = false;

    ngOnInit(): void {
        document.body.classList.add('page-404');
    }
    ngOnDestroy() {
        document.body.classList.remove('page-404');
    }

}
