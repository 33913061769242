import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { sectionsMetadata } from './metadata';

const routes: Routes = [
  { path: 'work/:client/campaign/:slug/:id', loadComponent: () => import('./pages/project/project.component').then(mod => mod.ProjectComponent), data: sectionsMetadata.workPage },
  { path: 'about', loadComponent: () => import('./pages/about/about.component').then(mod => mod.AboutComponent), data: sectionsMetadata.aboutPage },
  { path: 'contact', loadComponent: () => import('./pages/contact/contact.component').then(mod => mod.ContactComponent), data: sectionsMetadata.contactPage },
  { path: 'careers', loadComponent: () => import('./pages/careers/careers.component').then(mod => mod.CareersComponent), data: sectionsMetadata.careersPage },
  { path: 'capabilities', loadComponent: () => import('./pages/capabilities/capabilities.component').then(mod => mod.CapabilitiesComponent), data: sectionsMetadata.capabilitiesPage },
  { path: 'privacy-policy', loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(mod => mod.PrivacyPolicyComponent), data: sectionsMetadata.PrivacyPolicyPage },
  { path: 'terms-of-use', loadComponent: () => import('./pages/terms-of-use/terms-of-use.component').then(mod => mod.TermsOfUseComponent), data: sectionsMetadata.TermsOfUsePage },
  {
    path: 'work',
    loadComponent: () => import('./pages/projects/projects.component').then(mod => mod.ProjectsComponent),
    data: sectionsMetadata.workPage
  },
  {
    path: 'news', loadComponent: () => import('./pages/news/news.component').then(mod => mod.NewsComponent),
    data: sectionsMetadata.newsPage,
    children: [
      {
        path: 'work',
        loadComponent: () => import('./pages/news/work/work.component').then(mod => mod.WorkComponent),
        data: sectionsMetadata.newsPageWork
      },
      {
        path: 'people',
        loadComponent: () => import('./pages/news/people/people.component').then(mod => mod.PeopleComponent),
        data: sectionsMetadata.newsPagePeople
      },
      {
        path: 'perspectives',
        loadComponent: () => import('./pages/news/perspectives/perspectives.component').then(mod => mod.PerspectivesComponent),
        data: sectionsMetadata.newsPagePerspectives
      },
      {
        path: 'awards',
        loadComponent: () => import('./pages/news/awards/awards.component').then(mod => mod.AwardsComponent),
        data: sectionsMetadata.newsPageAwards
      },
    ]
  },
  { path: 'news/:id', loadComponent: () => import('./pages/news-item/news-item.component').then(mod => mod.NewsItemComponent), data: sectionsMetadata.newsItemPage },
  { path: '', loadComponent: () => import('./pages/home/home.component').then(mod => mod.HomeComponent), data: sectionsMetadata.homePage },
  { path: '**', component: PageNotFoundComponent, data: { page: '404' } }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  // constructor(router: Router) {
  //   // workaround to wait for dynamically created fragment targets
  //   setTimeout(() => {
  //     router.navigate([], {       // navigate to the same / current url
  //       preserveFragment: true,  // fragment to navigate
  //       skipLocationChange: true // prevent history pollution
  //     });
  //   }, 200);
  // }
}
