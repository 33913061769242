import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event, RouterLink } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy, NgIf } from '@angular/common';
import { ContactFormService } from '../../_services/contact-form.service';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  standalone: true,
  imports: [NgIf, RouterLink, FormsModule, HttpClientJsonpModule, HttpClientModule]
})
export class SiteFooterComponent implements OnInit {
  @Input() id: string;
  @Input() color: string;
  show = false;
  isAboutPage: boolean;
  isWorkPage: boolean;
  isContactPage: boolean;
  isHomePage: boolean;
  currentPage: string;
  isOpen = false;
  location: Location;
  currentYear: number = new Date().getFullYear();
  successMessage = 'Thank you for subscribing to';
  isSuccess: boolean;
  isSubscribedToBites: boolean;
  isSubscribedToScoop: boolean;
  private element: any;
  contact = {};
  subscribeData: any = {} as any;
  socialSubscribeData: any = {} as any;
  isActive: boolean = false;

  constructor(private cfs: ContactFormService,
    private el: ElementRef,
    location: Location,
    private router: Router) {
    this.element = el.nativeElement;
    this.location = location;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel || event instanceof NavigationError) {
        const isAbout = location.isCurrentPathEqualTo('/about');
        const isWork = location.isCurrentPathEqualTo('/work');
        const isContact = location.isCurrentPathEqualTo('/contact');
        const isHome = location.isCurrentPathEqualTo('/');

        if (isAbout) {
          this.isAboutPage = true;
          this.currentPage = 'About';
        } else {
          this.isAboutPage = false;
        }
        if (isWork) {
          this.isWorkPage = true;
          this.currentPage = 'Work';
        } else {
          this.isWorkPage = false;
        }
        if (isContact) {
          this.isContactPage = true;
          this.currentPage = 'Contact';
        } else {
          this.isContactPage = false;
        }
        if (isHome) {
          this.isHomePage = true;
          this.currentPage = 'Home';
        } else {
          this.isHomePage = false;
        }
      }
    });
    setTimeout(() => {
      this.show = true;
    }, 1800);
  }

  ngOnInit(): void {

  }

  socialScoopFormsubscribe(socialScoopForm: NgForm) {
    if (socialScoopForm.invalid) {
      return;
    }

    this.cfs.subscribeToList(this.socialSubscribeData)
      .subscribe(res => {
        this.isSuccess = true;
        this.isSubscribedToScoop = true;
        console.log('Subscribed!');
      }, err => {
        console.log(err);
      });
  }

  subscribe(contactForm: NgForm) {
    if (contactForm.invalid) {
      return;
    }

    this.cfs.subscribeToList(this.subscribeData)
      .subscribe(res => {
        this.isSuccess = true;
        this.isSubscribedToBites = true;
        console.log('Subscribed!');
      }, err => {
        console.log(err);
      });
  }
}
