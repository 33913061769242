export const environment = {
	production: false,
	contentful_config: {
		space: 'uwpg4vevsh2j',
		accessToken: 'UYJms2qgmqnH79pJc1cw8yA8ihnYU8XlpWD_fTzUsT4',
		environment: 'staging',
		contentTypeIds: {
			aboutPage: 'aboutPage',
			privacyPolicyPage: 'privacyPolicyPage',
			termsAndConditionsPage: 'termsAndConditionsPage',
			termsOfUsePage: 'termsOfUsePage',
			contactPage: 'contactPage',
			homePage: 'homePage',
			capabilitiesPage: 'capabilitiesPage',
			careersPage: 'careersPage',
			workPage: 'workPage',
			newsPage: 'newsPage',
			work: 'work',
			clients: 'clients',
			spot: 'spot',
			news: 'news'
		}
	}
};
