import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	transform(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
