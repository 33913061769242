import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'comma',
    standalone: true
})
export class CustomCommaPipe implements PipeTransform {
	transform(input: number, customPluralForm: string = ","): string {
		return input > 1 ? customPluralForm : "";
	}
}
