import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class ContactFormService {
	mailChimpEndpoint = 'https://zmbz.us2.list-manage.com/subscribe/post-json?u=bb5bb81574f5e4fef67f57367&id=6dc6c8c292&f_id=00195fe0f0';
	// <input type="hidden" name="u" value="bb5bb81574f5e4fef67f57367">
	// <input type="hidden" name="id" value="6dc6c8c292">
	// scale below
	// 'https://zmbz.us14.list-manage.com/subscribe/post-json?u=78f32943335136dca715059e1&id=43ab8e7cfc';

	constructor(private http: HttpClient) { }

	subscribeToList(data) {
		const params = new HttpParams()
			.set('EMAIL', data.email)
			// .set('group[46041][1]', 'true')
			.set('1b77216623ac88a9069b7d7bb7bc79486a7cf167:MTY2NDMzOTA2MC4zMjU0', '');
		const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
		return this.http.jsonp(mailChimpUrl, 'c');
	}
}
