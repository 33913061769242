<footer class="footer section is-medium" *ngIf="show">
	<div class="container is-fluid">
		<div class="footer__row row--top">
			<div class="columns">
				<div class="column">
					<div class="footer__logo">
						<a routerLink="/"><img src="assets/logos/Zambezi_Wordmark.svg" alt="logo"></a>
					</div><br />
					<div class="footer__logos">
						<a href="https://www.scalebyzmbz.com/" target="_blank" rel="noopener noreferrer"><img
								class="logo logo--scale"
								src="assets/logos/scale-logo.svg" alt="logo"></a>
						<span><img src="../../../../assets/icons/footer-divider.svg" /></span>
						<a href="http://www.finstudios-la.com/" target="_blank" rel="noopener noreferrer"><img
								class="logo logo--fin"
								src="assets/logos/fin-logo.svg" alt="logo"></a>
						<span><img src="../../../../assets/icons/footer-divider.svg" /></span>
						<a href="https://www.instagram.com/zmbzagency/" target="_blank" rel="noopener noreferrer"><img
								class="logo logo--school" src="assets/logos/school-logo.svg" alt="logo"></a>
					</div>
				</div>
				<div class="column column--forms">
					<form id="contact-form" class="contact__form form" #contactForm="ngForm">
						<div class="is-success" *ngIf="isSuccess && isSubscribedToBites">
							{{successMessage}} Bites!
						</div>
						<div class="form__field field" *ngIf="!isSubscribedToBites">
							<div class="form__control control">
								<input id="email" maxlength="100" class="form__input input" pattern="\S+@\S+\.\S+"
									required #email='ngModel'
									name='email' [(ngModel)]='subscribeData.email' placeholder="SUBSCRIBE TO BITES"
									appDetectFocus
									autocomplete="off" />
								<span *ngIf="email.errors && email.touched" class="signup-form__error">Email is
									required</span>
								<span class="bar"></span>
							</div>
							<button type="submit" role="button" (click)='subscribe(contactForm)'
								[disabled]="contactForm.invalid"
								class="submit-button button">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									width="25px" height="21px">
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M14.306,19.418 L23.498,10.226 C23.693,10.031 24.010,10.031 24.205,10.226 C24.400,10.421 24.400,10.738 24.205,10.933 L15.013,20.126 C14.817,20.321 14.501,20.321 14.306,20.126 C14.110,19.930 14.110,19.614 14.306,19.418 Z"/>
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M15.013,0.327 L24.205,9.519 C24.400,9.714 24.400,10.031 24.205,10.226 C24.010,10.421 23.693,10.421 23.498,10.226 L14.306,1.034 C14.110,0.839 14.110,0.522 14.306,0.327 C14.501,0.132 14.817,0.132 15.013,0.327 Z"/>
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M0.834,9.708 L22.834,9.866 C23.110,9.868 23.332,10.093 23.330,10.370 C23.328,10.646 23.103,10.868 22.827,10.866 L0.827,10.708 C0.551,10.706 0.329,10.480 0.331,10.204 C0.333,9.928 0.558,9.706 0.834,9.708 Z"/>
									</svg>
							</button>
						</div>
					</form>
					<form id="social-scoop-form" class="social-scoop-form form" #socialScoopForm="ngForm">
						<div class="is-success" *ngIf="isSuccess && isSubscribedToScoop">
							{{successMessage}} Social Scoop!
						</div>
						<div class="form__field field" *ngIf="!isSubscribedToScoop">
							<div class="form__control control">
								<input id="email2" maxlength="100" class="form__input input" pattern="\S+@\S+\.\S+"
									required #email2='ngModel'
									name='email2' [(ngModel)]='socialSubscribeData.email2'
									placeholder="SUBSCRIBE TO SOCIAL SCOOP"
									appDetectFocus
									autocomplete="off" />
								<span *ngIf="email2.errors && email2.touched" class="signup-form__error">Email is
									required</span>
								<span class="bar"></span>
							</div>
							<button type="submit" role="button" (click)='socialScoopFormsubscribe(socialScoopForm)'
								[disabled]="socialScoopForm.invalid"
								class="submit-button button">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									width="25px" height="21px">
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M14.306,19.418 L23.498,10.226 C23.693,10.031 24.010,10.031 24.205,10.226 C24.400,10.421 24.400,10.738 24.205,10.933 L15.013,20.126 C14.817,20.321 14.501,20.321 14.306,20.126 C14.110,19.930 14.110,19.614 14.306,19.418 Z"/>
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M15.013,0.327 L24.205,9.519 C24.400,9.714 24.400,10.031 24.205,10.226 C24.010,10.421 23.693,10.421 23.498,10.226 L14.306,1.034 C14.110,0.839 14.110,0.522 14.306,0.327 C14.501,0.132 14.817,0.132 15.013,0.327 Z"/>
									<path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
									d="M0.834,9.708 L22.834,9.866 C23.110,9.868 23.332,10.093 23.330,10.370 C23.328,10.646 23.103,10.868 22.827,10.866 L0.827,10.708 C0.551,10.706 0.329,10.480 0.331,10.204 C0.333,9.928 0.558,9.706 0.834,9.708 Z"/>
									</svg>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="footer__row row--bottom">
			<div class="columns">
				<div class="column">
					ZMBZ WEST<br />
					3522 Hayden Ave<br />
					Culver City, CA 90232<br />
					USA
				</div>
				<div class="column">
					ZMBZ NORTH<br />
					124 3rd Ave N, Suite 302<br />
					Minneapolis, MN 55401<br />
					USA
				</div>
				<div class="column footer__partner">
					PARTNER WITH US<br />
					newbusiness@zmbz.com <br />
					310.450.6800
				</div>
				<div class="column footer__links">
					<a class="menu__item" routerLink="/terms-of-use" routerLinkActive="active">Terms of Use</a>
					<a class="menu__item" routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a>
				</div>
				<div class="column footer__social">
					<a href="https://www.instagram.com/zmbzagency/" target="_blank" rel="noopener noreferrer"
						class="footer__social-link">INSTAGRAM</a>
					<a href="https://www.tiktok.com/@zmbzagency" target="_blank" rel="noopener noreferrer"
						class="footer__social-link">TIKTOK</a>
					<a href="https://twitter.com/zmbzagency/" target="_blank" rel="noopener noreferrer"
						class="footer__social-link">TWITTER</a>
					<a href="https://www.linkedin.com/company/zmbz/" target="_blank" rel="noopener noreferrer"
						class="footer__social-link">LINKEDIN</a>
					<a href="https://www.facebook.com/zmbzagency/" target="_blank" rel="noopener noreferrer"
						class="footer__social-link">FACEBOOK</a>
				</div>
			</div>
			<p class="legal">©{{currentYear}} Zambezi</p>
		</div>
	</div>
</footer>
