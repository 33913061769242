<app-nav></app-nav>
<div class="app-wrapper" id="smooth-content">
  <main class="main">
    <div class="router-wrapper">
      <router-outlet #outletRef="outlet"></router-outlet>
    </div>
  </main>
  <app-site-footer></app-site-footer>
</div>
<div class="loader__panel page-wipe-blue"></div>
<div class="loader__panel page-wipe-white">
  <svg>
  <defs>
    <filter id="ripple-filter">
      <feImage x="40" y="50" width="0%" height="0%" result="rippleImage" />

      <feDisplacementMap id="displacement-map"
                         xChannelSelector="R"
                         yChannelSelector="G"
                         in="SourceGraphic"
                         in2="rippleImage"
                         result="displacementMap"
                         color-interpolation-filters="sRGB"
                         scale="0" />

      <feComposite operator="in" in2="rippleImage"></feComposite>
    </filter>
  </defs>

  <g id="logo">
    <image id="logo-image"
           width="162.81"
           height="141.5"
           xlink:href="../../assets/logos/router_animation_logo.svg" />

    <image id="logo-overlay"
           width="162.81"
           height="141.5"
           xlink:href="../../assets/logos/router_animation_logo.svg"
           filter="url(#ripple-filter)" />
  </g>
</svg>

</div>
